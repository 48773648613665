<template>
  <section class="page-content">
    <download-data page-type="_pa_" v-if="!disabled" />
    <p class="description">
      Ogłoszenie promocyjne służy promocji Twojej marki pracodawcy wśród
      użytkowników serwisu Pracuj.pl. Użytkownicy dowiedzą się o Twoje obecności
      na JOBICONIE.
    </p>
    <p class="description">
      Wszystkie ogłoszenia promocyjne posiadają taki sam tytuł oraz są
      przygotowane w szacie graficznej JOBICON. Ogłoszenie można opublikować na
      30 dni przed wydarzeniem. Klikając w „Aplikuj” użytkownik zostanie
      przekierowany do pobrania biletu na wydarzenie.
    </p>
    <form v-if="this.namedDetails !== undefined" class="main-form">
      <h2>Ogłoszenia promocyjne:</h2>
      <v-radio-button
        id="false"
        name="_pa_english"
        label="Język polski – Poznaj nas na Festiwalu Pracy JOBICON"
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._pa_english"
      />
      <v-radio-button
        id="true"
        name="_pa_english"
        label="Język angielski – Meet us at the JOBICON Job Festival"
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._pa_english"
      />
      <h3 class="margin-tp-4 margin-bt-0">Kategorie ogłoszenia</h3>
      <tags-select
        :tags-header="
          formData._pa_english === true ? headers.en.tags : headers.pl.tags
        "
        :tags-list="tagsList"
        :tags-limit="2"
        :name="`_pa_category`"
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData[`_pa_category`]"
      />
      <div v-if="userRole === 'administrator' && 0 === 1">
        <h2>Dla administratorów</h2>
        <p class="description">
          Wygeneruj i pobierz plik Word z treściami z tej zakładki
        </p>
        <a
          :href="wordDownloadLink ? wordDownloadLink : null"
          class="btn"
          @click="
            wordDownloadLink
              ? null
              : setWordDownloadLink(keysForWordDownload, formData._pa_english)
          "
          download
          >{{ wordDownloadLink ? "Pobierz plik" : "Wygeneruj plik" }}</a
        >
      </div>
      <div v-if="!disabled" class="send-btn-cont">
        <main-btn
          content="Zapisz wersję roboczą"
          tooltip="Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia."
          :submit="true"
          :saving="loading"
          :gray="true"
          @click="sendData(false)"
        />
        <main-btn
          :disabled="charactersLeft < 0"
          :content="saveFinalDataText"
          :submit="true"
          tooltip="Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia"
          :saving="loading"
          @click="sendData(true, '_pa_disabled')"
        />
      </div>
      <div v-else>
        <p>
          <strong>
            Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
            dokonać zmian skontaktuj się pod adresem
            <a class="important-info" :href="`mailto:${companyGuardian.email}`">
              {{ companyGuardian.email }}
            </a>
          </strong>
        </p>
      </div>
      <p class="text-center margin-tp-1" v-show="message">
        <strong :class="`message--${messageType}`">{{ message }}</strong>
      </p>
    </form>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import commonContentLengthForTextarea from "@/mixins/commonContentLengthForTextarea.js";
import wordDownload from "@/mixins/wordDownload.js";

export default Vue.extend({
  mixins: [
    sendDataAndDispatch,
    assignDataFromDatabase,
    commonContentLengthForTextarea,
    wordDownload,
  ],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      headers: {
        pl: {
          who: "Kogo szukamy?",
          prepared: "Co przygotowaliśmy na wydarzeniu?",
          what_we_offer: "Co oferujemy?",
          tags: "Wybierz maksymalnie dwie kategorie, w których ma wyświetlać się ogłoszenie.",
        },
        en: {
          who: "Who are we looking for?",
          prepared: "What have we prepared for the event?",
          what_we_offer: "What we offer?",
          tags: "Select up two categories.",
        },
      },
      formData: {
        _pa_english: false,
        _pa_category: [],
      },
      contentLength: {},
      tagsList: [
        "Administracja biurowa",
        "Badania i rozwój",
        "Bankowość",
        "BHP / Ochrona środowiska",
        "Budownictwo",
        "Call Center",
        "Doradztwo / Konsulting",
        "Edukacja / Szkolenia",
        "Energetyka",
        "Finanse / Ekonomia",
        "Franczyza / Własny biznes",
        "Hotelarstwo / Gastronomia / Turystyka",
        "Human Resources / Zasoby ludzie",
        "Internet / e-Commerce / Nowe media",
        "Inżynieria",
        "IT – Administracja",
        "IT – Rozwój oprogramowania",
        "Łańcuch dostaw",
        "Kontrola jakości",
        "Marketing",
        "Media / Sztuka / Rozrywka",
        "Nieruchomości",
        "Obsługa klienta",
        "Praca fizyczna",
        "Prawo",
        "Produkcja",
        "Public Relations",
        "Reklama / Grafika / Kreacja / Fotografia",
        "Sektor publiczny",
        "Sprzedaż",
        "Transport / Spedycja",
        "Ubezpieczenia",
        "Zakupy",
        "Zdrowie / Uroda / Rekreacja",
        "Inne",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "companyData",
      "companyGuardian",
      "activeEdition",
      "activeEditionOpened",
      "userRole",
    ]),
    keysForWordDownload() {
      return [
        `${this.activeEdition}_pa_who_we_are_looking_for`,
        `${this.activeEdition}_pa_what_we_prepared`,
        `${this.activeEdition}_pa_what_we_offer`,
      ];
    },
  },
  methods: {
    checkIfPageIsDisabled() {
      this.disabled = this.formData._pa_disabled;

      if (!this.activeEditionOpened) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData("_pa_english", false, "boolean");
      this.assingData("_pa_disabled", false, "boolean");
      this.assingData(`_pa_category`, [], "array");
    },
  },
  mounted() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  watch: {
    companyData: function () {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>
